async function filterTrait(trait) {
    if (trait === "Glitched")
        return ["Glitched 1", "Glitched 2", "Glitched 3"];
    if (trait === "Balloons")
        return ["Black Balloons"];
    if (trait === "Knife")
        return ["Knife1"];
    if (trait === "Red Flames")
        return ["Angry Flames"];
    if (trait === "Hollow")
        return ["Hollow 2"];
    if (trait === "Trip")
        return ["Trip 2"];
    if (trait === "Purple Flames")
        return ["Purple Flames Black ver"];
    if (trait === "Hood Down - Dark Gray")
        return ["Dark Gray Hoodie"];
    if (trait === "Hood Down - Cool Dark")
        return ["Cool Hoodie Dark"];
    if (trait === "Hood Down - Gray Blue")
        return ["Gray Blue Hoodie"];
    if (trait === "Hood Down - Light Blue")
        return ["Light Blue Hoodie"];
    if (trait === "Hood Up - Gray Camo")
        return ["Gray Camo"];
    if (trait === "Hood Up - Camo")
        return ["Camo"];
    if (trait === "Hood Down - Black")
        return ["Black Hoodie"];
    if (trait === "Hood Up - Black")
        return ["Full Black Hoodie"];
    if (trait === "Hood Up - Blue")
        return ["Full Blue Hoodie"];
    if (trait === "Hood Up - Dark Gray")
        return ["Full Dark Gray Hoodie"];
    if (trait === "Hood Down - Dark Blue")
        return ["Dark Blue Hoodie"];
    if (trait === "Hood Down - Cool")
        return ["Cool Hoodie"];
    if (trait === "Hood Up - Light Purple")
        return ["Full Light Purple Hoodie"];
    if (trait === "Hood Up - Purple")
        return ["Full Purple Hoodie"];
    if (trait === "Mello")
        return ["Mello Shiny"];
    if (trait === "Comfy")
        return ["White Comfy"];
    if (trait === "Lollipop")
        return ["Pink Lollipop"];
    if (trait === "Stressed")
        return ["Cigar Psycho"];
    if (trait === "Snake Bites")
        return ["Gold Snake Bites"];
    if (trait === "That Fire")
        return ["Fire Shit"];
    if (trait === "Angel")
        return ["Red Angel"];
    if (trait === "Adventure")
        return ["Adventure black"];
    if (trait === "Sucker")
        return ["Lollipop"];
    if (trait === "Bull Horns")
        return ["Horns"];
    if (trait === "Bull Horns")
        return ["Black Horns"];
    if (trait === "Chainsaw")
        return ["Chainsaw Frog"];
    if (trait === "Chainsaw")
        return ["Bloody Chainsaw Frog"];
    if (trait === "Crow")
        return ["Armed Crow"];
    else
        return [trait];
}

export default filterTrait;