import { useAccount } from 'wagmi';
import { useRef, useState, useEffect } from 'react';
import './styles/App.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { useLocation, useParams } from 'react-router-dom';
import specs from './utils/specs';
import traitSpecs from './utils/traitSpecs';
import filterTrait from './utils/traitFilter';
import { isBigInt64Array } from 'util/types';

const apiUrl = process.env.REACT_APP_API_URL;
const BOMEFERS_CONTRACT_ADDRESS = '0xe3150c35226e5afecc58f159d79d5bcc3f82e33f';
const BOMEFER_TRAITS_CONTRACT_ADDRESS = '0x92061a8da7a7bb08ad70e43cc15db3b942e21ed5';
const oneOfOneBomefers = [
  "1353", "414", "245", "2308", "3519", "4082", "1236", "2813", "1320", "2242", 
  "2458", "3962", "2331", "1091", "3734", "743", "3313", "720", "3930", "795", 
  "756", "2456", "3295", "1212", "1161", "2680", "983", "3457", "872", "1143", 
  "2459", "2821", "3886", "2234", "1556", "2328", "235", "3294", "3588", "2035", 
  "2091", "2756", "3230", "1978", "813", "2900", "2798", "793", "1082", "1434", 
  "2499", "1303", "1960", "2441", "2592", "3922"
];

interface Token {
  "Back": string;
  "Background": string;
  "Body": string;
  "Cigar": string;
  "Clothes": string;
  "Eyes": string;
  "Head": string;
  "Mouth": string;
  "Specials": string;
}

interface SelectedTraits {
  [key: string]: string;
}

const DressingRoom = () => {
  const { tokenId } = useParams();
  const [tokenMetadata, setTokenMetadata] = useState<Token>();
  const [layers, setLayers] = useState<{ [key: string]: string[] }>();
  const [selectedTraits, setSelectedTraits] = useState<{ [key: string]: string }>({});
  const [ownedTraits, setOwnedTraits] = useState<{ type: string; value: string }[]>([]);
  const [sortedLayers, setSortedLayers] = useState<{ file: string; priority: number }[]>([]);
  const [owner, setOwner] = useState<string>('');
  const { address, isConnected } = useAccount();
  const state = useLocation();

  const categories = [
    "Back",
    "Background",
    "Body",
    "Cigar",
    "Clothes",
    "Eyes",
    "Head",
    "Mouth",
    "Specials"
  ];

  // Fetch token metadata
  async function getToken() {
    const res = await fetch(`${apiUrl}/items/token?contract=${BOMEFERS_CONTRACT_ADDRESS}&id=${tokenId}`);
    const result = await res.json();
    console.log(result.item.mint);
    let token: Token = {
      "Back": '',
      "Background": '',
      "Body": '',
      "Cigar": '',
      "Clothes": '',
      "Eyes": '',
      "Head": '',
      "Mouth": '',
      "Specials": ''
    };
    result.item.raw.metadata.attributes.forEach((attr: any) => {
      if (attr.trait_type in token) {
        token[attr.trait_type as keyof Token] = attr.value;
      }
    });
    setTokenMetadata(token);
  }

  async function getOwner() {
    const res = await fetch(`${apiUrl}/items/owner?contract=${BOMEFERS_CONTRACT_ADDRESS}&id=${tokenId}`);
    const result = await res.json();
    setOwner(result.owner.owners[0]);
    console.log(owner);
  }

  // Fetch layers for each trait category
  async function getLayers(token: Token) {
    let layers: { [key: string]: string[] } = {};
    for (let category in categories) {
      if (token[categories[category] as keyof Token] && token[categories[category] as keyof Token] !== '') {
        let trait = token[categories[category] as keyof Token];
        const filteredTraits = await filterTrait(trait);
        let possibleLayers = [];
        for (let i in filteredTraits) {
          let layer = (specs[categories[category] as keyof Token] as any)[filteredTraits[i]]["files"];
          possibleLayers.push(layer);
        }
        layers[categories[category]] = possibleLayers;
      } else {
        layers[categories[category]] = [(specs[categories[category] as keyof Token] as any)["None"]["files"]];
      }
      console.log("owned traits:")
      console.log(ownedTraits);
      for (let i in ownedTraits) {
        console.log(ownedTraits[i].type, ownedTraits[i].value);
        if (ownedTraits[i].type === categories[category]) {
          console.log("matches")
          const trait = (traitSpecs as any)[ownedTraits[i].type][ownedTraits[i].value]["files"];
          layers[categories[category]].push(trait);
        }
      }
      console.log(layers);
    }
    setLayers(layers);
  }

  async function getOwnedTraits() {
    const res = await fetch(`${apiUrl}/items?address=${address}&contract=${BOMEFER_TRAITS_CONTRACT_ADDRESS}`);
    const result = await res.json();
    let traits: { type: string; value: string }[] = [];
    result.items[0].forEach((item: any) => {
      let trait = { type: '', value: '' };
      trait.type = item.raw.metadata.attributes[0].trait_type;
      trait.value = item.raw.metadata.attributes[0].value;
      traits.push(trait);
    });
    setOwnedTraits(traits);
  }

  const downloadImage = () => {
    const canvas = document.querySelector('canvas');
    const link = document.createElement('a');
    link.download = 'layered-image.png';
    if (canvas) {
      link.href = canvas.toDataURL();
    }
    link.click();
  };

  interface Layer {
    file: string;
    priority: number;
  }

  const LayeredImage = ({ sortedLayers }: { sortedLayers: Layer[] }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');

      const drawImageLayer = async (src: string): Promise<void> => {
        return new Promise<void>((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            if (ctx) {
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
            resolve();
          };
        });
      };

      const drawImages = async () => {
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
        }

        for (const layer of sortedLayers) {
          await drawImageLayer(`/images/traits/${layer.file}`); // assuming the images are stored in a public/images directory
        }
      };

      drawImages();
    }, [sortedLayers]);

    return <canvas ref={canvasRef} width={600} height={600} />;
  };

  const getLayerImages = (userSelection: { [key: string]: string }, layers: { [key: string]: string[] }) => {
    let layerImages = [];

    for (const [layer, imageName] of Object.entries(userSelection)) {
      // Check if the layer exists in the layers object
      if (layers[layer]) {
        const layerArray = layers[layer];

        // Find the image in the current layer and get its priority
        const foundImage = layerArray.find(imageObj => Object.keys(imageObj)[0] === imageName);

        if (foundImage) {
          const file = Object.keys(foundImage)[0];
          //@ts-ignore
          const priority = foundImage[file];
          layerImages.push({ file, priority });
        }
      }
    }

    // Sort by priority
    layerImages.sort((a, b) => a.priority - b.priority);

    return layerImages;
  };

  useEffect(() => {
    if (isConnected) {
      getToken();
      getOwnedTraits();
      getOwner();
    }
  }, [state]);

  useEffect(() => {
    if (tokenMetadata) {
      getLayers(tokenMetadata);
    }
  }, [ownedTraits]);

  // Set default selected traits when layers are set
  useEffect(() => {
    if (layers) {
      const defaultSelected: { [key: string]: string } = {};
      Object.keys(layers).forEach((category) => {
        if (layers[category].length > 0) {
          const traitName = Object.keys(layers[category][0])[0];  // Get the first trait's name
          defaultSelected[category] = traitName; // Set the first trait as the default
        }
      });
      setSelectedTraits(defaultSelected);
    }
  }, [layers]);

  useEffect(() => {
    if (layers) {
      const sortedLayers = getLayerImages(selectedTraits, layers)
      setSortedLayers(sortedLayers);
    }
  }, [selectedTraits]);

  // Handle trait selection
  const handleTraitClick = (category: string, trait: string) => {
    setSelectedTraits((prevSelectedTraits) => ({
      ...prevSelectedTraits,
      [category]: trait,
    }));
  };

  return (
    <div>
      {isConnected && owner == address && tokenId && !oneOfOneBomefers.includes(tokenId) ? (
        <div className={"container"}>
          <main className={"main"}>
            <Nav />
            <h1 className={"mainHeading"}>Dressing Room</h1>
            <p className='mainHeading'>{`Bomefer #${tokenId}`}</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {layers ? (
                <div style={{maxHeight: "600px", overflowY: "scroll", width: "600px"}} className='traitBox'>
                  {Object.keys(layers).map((category) => (
                    <section key={category}>
                      <h2 className='cardHeading'>{category}</h2>
                      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        {layers[category].map((trait, index) => {
                          const traitName = Object.keys(trait)[0];
                          return (
                            <div
                              key={index}
                              style={{ textAlign: "center", cursor: "pointer", opacity: selectedTraits[category] === traitName ? "100%" : "50%" }}
                              onClick={() => handleTraitClick(category, traitName)}
                            >
                              <img
                                src={`/images/traits/${traitName}`}
                                alt={traitName}
                                style={{ width: "100px", height: "100px", background: "grey" }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </section>
                  ))}
                </div>
              ) : null}
              {sortedLayers.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <LayeredImage sortedLayers={sortedLayers} />
                  <a className='cardHeading' onClick={downloadImage} style={{ cursor: 'pointer', alignSelf: "center", color: 'red' }}>Download Image</a>
                </div>
              )}
            </div>
          </main>
          <Footer />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <h1 className='mainHeading'>You don't own this Bomefer</h1>
        </div>
      )}
    </div>
  );
};

export default DressingRoom;
