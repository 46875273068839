// pages/raffles.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RaffleCard from './components/RaffleCard';
import { useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Nav from './components/Nav';
import Footer from './components/Footer';
import './styles/Raffles.css';

const apiUrl = process.env.REACT_APP_API_URL;

const Raffles = () => {
  interface Item {
    tokenId: string;
    name: string;
    image: string;
    balance: number;
  }

  const [raffles, setRaffles] = useState([]);

  const [items, setItems] = useState<Item[]>([]);

  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

  const { address, isConnected } = useAccount()

  const fetchRaffles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/raffles`);
      setRaffles(response.data);
    } catch (error) {
      console.error('Error fetching raffles:', error);
    }
  };

  async function getItems() {
    const res = await fetch(`${apiUrl}/items?address=${address}&contract=${CONTRACT_ADDRESS}`);
    const userItems = [];
    const result = await res.json();
    for (let x in result.items) {
      for (let y in result.items[x]) {
        if (parseInt(result.items[x][y].tokenId) >= 1) {
          userItems.push(result.items[x][y]);
        }
      }
    }
    let cleanedItems = [];
    for (let x in userItems) {
      let item = {
        tokenId: userItems[x].tokenId,
        name: userItems[x].name,
        image: userItems[x].image.pngUrl,
        balance: parseInt(userItems[x].balance)
      }
      cleanedItems.push(item);
    }
    setItems(cleanedItems);
  }

  useEffect(() => {
    if (isConnected) {
      getItems();
    }
  }, [isConnected]);

  useEffect(() => {
    fetchRaffles();
  }, []);

  return (
    <div className={"container"}>
      <main className={"main"}>
        <Nav />
        <h1 className={"pageHeading"}>Raffles</h1>
        <div className="raffle-list">
          {raffles.map((raffle: { _id: string }) => (
            <RaffleCard key={raffle._id} raffle={raffle} address={address} tokenIds={items.map(item => item.tokenId)} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Raffles;
