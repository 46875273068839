import { ConnectButton } from '@rainbow-me/rainbowkit';

const Nav = () => {
    return (
        <footer className={"footer"}>
            <p style={{ fontFamily: "sans-serif" }}>© Bomefers</p>
            <a href="https://twitter.com/bomefers" target='blank_'><img className={"socialIcon"} src="images/x-logo-black.png"></img></a>
        </footer>
    )
}

export default Nav;