const specs = {
    "Background" : {
        "Bloody Red" : {
            "files" : {
                "Bloody Red.png" : 10,
                },
            "probability" : 4
        },
        "Calypso" : {
            "files" : {
                "Calypso.png" : 10,
                },
            "probability" : 6
        },
        "Are ya winning, Son?" : {
            "files" : {
                "Father.png" : 10,
                },
            "probability" : 1
        },
        "Fog" : {
            "files" : {
                "Fog.png" : 10,
                },
            "probability" : 7
        },
        "Gray" : {
            "files" : {
                "Gray.png" : 10,
                },
            "probability" : 7
        },
        "Light Blue" : {
            "files" : {
                "Light Blue.png" : 10,
                },
            "probability" : 6
        },
        "Light Green" : {
            "files" : {
                "Light Green.png" : 10,
                },
            "probability" : 7
        },
        "Light Purple" : {
            "files" : {
                "Light Purple.png" : 10,
                },
            "probability" : 6
        },
        "Mango" : {
            "files" : {
                "Mango.png" : 10,
                },
            "probability" : 5
        },
        "Pink" : {
            "files" : {
                "Pink.png" : 10,
                },
            "probability" : 6
        },
        "Purple" : {
            "files" : {
                "Purple.png" : 10,
                },
            "probability" : 8
        },
        "Glitched 1" : {
            "files" : {
                "Glitched 1.png" : 10,
                },
            "probability" : 3
        },
        "Glitched 2" : {
            "files" : {
                "Glitched 2.png" : 10,
                },
            "probability" : 1
        },
        "Glitched 3" : {
            "files" : {
                "Glitched 3.png" : 10,
                },
            "probability" : 1
        },
    },
    "Specials" : {
        "Balloons" : {
            "files" : {
                "Balloons.png" : 20
                },
            "probability" : 5
        },
        "Sakura Bloom" : {
            "files" : {
                "Sakura Bloom.png" : 20
                },
            "probability" : 2
        },
        "Black Balloons" : {
            "files" : {
                "Black Balloons.png" : 20
                },
            "probability" : 3
        },
        "Black Star" : {
            "files" : {
                "Black Star.png" : 20
                },
            "probability" : 5
        },
        "Bubbles" : {
            "files" : {
                "Bubbles.png" : 20
                },
            "probability" : 8
        },
        "CCTV" : {
            "files" : {
                "CCTV.png" : 20
                },
            "probability" : 6
        },
        "Doomed" : {
            "files" : {
                "Doomed.png" : 20
                },
            "probability" : 6
        },
        "Judge" : {
            "files" : {
                "Judge.png" : 20
                },
            "probability" : 5
        },
        "knife 1" : {
            "files" : {
                "Knife1.png" : 20
                },
            "probability" : 7
        },
        "Mutatio" : {
            "files" : {
                "Mutatio.png" : 20
                },
            "probability" : 8
        },
        "None" : {
            "files" : {
                "None.png" : 20
                },
            "probability" : 50
        },
        "Red Bubbles" : {
            "files" : {
                "Red Bubbles.png" : 20
                },
            "probability" : 7
        },
        "Sartoshi" : {
            "files" : {
                "Sartoshi.png" : 20
                },
            "probability" : 4
        },
        "Snow" : {
            "files" : {
                "Snow.png" : 20
                },
            "probability" : 8
        },
        "Sparkles" : {
            "files" : {
                "Sparkles.png" : 20
                },
            "probability" : 8
        },
        "Bad Ghosts" : {
            "files" : {
                "Bad Ghosts.png" : 20
                },
            "probability" : 2
        },
        "Controversial" : {
            "files" : {
                "Controversia.png" : 20
                },
            "probability" : 7
        },
        "Ghosts" : {
            "files" : {
                "Ghosts.png" : 20
                },
            "probability" : 4
        },
        "This is Fine" : {
            "files" : {
                "Enriched Flames.png" : 20
                },
            "probability" : 2
        },
    },
    "Back" : {
        "Bat Wings" : {
            "files" : {
                "Bat Wings.png" : 30
                },
            "probability" : 4
        },
        "Buster Sword" : {
            "files" : {
                "Buster Sword.png" : 30
                },
            "probability" : 5
        },
        "Flag" : {
            "files" : {
                "Flag.png" : 30
                },
            "probability" : 2
        },
        "Golden Toilet" : {
            "files" : {
                "Gold Toile.png" : 30
                },
            "probability" : 2
        },
        "Katana" : {
            "files" : {
                "Katana.png" : 30
                },
            "probability" : 6
        },
        "None" : {
            "files" : {
                "None.png" : 30
                },
            "probability" : 45
        },
        "Herman Miller" : {
            "files" : {
                "Office Chair.png" : 30
                },
            "probability" : 8
        },
        "Sand Gourd" : {
            "files" : {
                "Sand Gourd.png" : 30
                },
            "probability" : 6
        },
        "Steel Chair" : {
            "files" : {
                "Steel Chair.png" : 30
                },
            "probability" : 8
        },
        "Toilet" : {
            "files" : {
                "Toile.png" : 30
                },
            "probability" : 5
        },
        "Tub" : {
            "files" : {
                "Tub.png" : 30
                },
            "probability" : 6
        },
        "Big Cross" : {
            "files" : {
                "Big Cross.png" : 30
                },
            "probability" : 4
        },
        "Flail" : {
            "files" : {
                "Morning Star.png" : 30
                },
            "probability" : 5
        },
        "Scythe" : {
            "files" : {
                "Scythe.png" : 30
                },
            "probability" : 3
        },
    },
    "Body" : {
        "Acne" : {
            "files" : {
                "Acne.png" : 40
                },
            "probability" : 4
        },
        "Degen" : {
            "files" : {
                "Degen.png" : 40
                },
            "probability" : 7
        },
        "Exotic" : {
            "files" : {
                "Exotic.png" : 40
                },
            "probability" : 6
        },
        "Green" : {
            "files" : {
                "PEPE.png" : 40
                },
            "probability" : 13
        },
        "Plum" : {
            "files" : {
                "Plum PEPE.png" : 40
                },
            "probability" : 7
        },
        "Zombie" : {
            "files" : {
                "Zombie.png" : 40
                },
            "probability" : 6
        },
        "Inked" : {
            "files" : {
                "Inked.png" : 40
                },
            "probability" : 2
        },
    },
    "Eyes" : {
        "Alien" : {
            "files" : {
                "Alien_eyes.png" : 50
                },
            "probability" : 2
        },
        "Angry Flames" : {
            "files" : {
                "Angry Flames.png" : 50
                },
            "probability" : 3
        },
        "Arrows" : {
            "files" : {
                "Arrows.png" : 50
                },
            "probability" : 7
        },
        "Blackout" : {
            "files" : {
                "Blackout.png" : 50
                },
            "probability" : 8
        },
        "Chill" : {
            "files" : {
                "Chill.png" : 50
                },
            "probability" : 8
        },
        "Concentrated" : {
            "files" : {
                "Concentrated.png" : 50
                },
            "probability" : 8
        },
        "DAMAGER LUXE" : {
            "files" : {
                "DAMAGER LUXE.png" : 50
                },
            "probability" : 5
        },
        "Degen Hours" : {
            "files" : {
                "Degen Hours.png" : 50
                },
            "probability" : 8
        },
        "Evil" : {
            "files" : {
                "Evil.png" : 50
                },
            "probability" : 7
        },
        "Excited" : {
            "files" : {
                "Excited.png" : 50
                },
            "probability" : 7
        },
        "Fancy" : {
            "files" : {
                "Fancy.png" : 50
                },
            "probability" : 6
        },
        "FPS" : {
            "files" : {
                "FPS.png" : 50
                },
            "probability" : 8
        },
        "Full" : {
            "files" : {
                "Full.png" : 50
                },
            "probability" : 6
        },
        "Hollow 2" : {
            "files" : {
                "Hollow 2.png" : 50
                },
            "probability" : 5
        },
        "Hollow" : {
            "files" : {
                "Hollow.png" : 50
                },
            "probability" : 7
        },
        "Hypnotized" : {
            "files" : {
                "Hypnotized.png" : 50
                },
            "probability" : 7
        },
        "Money" : {
            "files" : {
                "Money.png" : 50
                },
            "probability" : 6
        },
        "None" : {
            "files" : {
                "None.png" : 50
                },
            "probability" : 15
        },
        "Parking" : {
            "files" : {
                "Parking.png" : 50
                },
            "probability" : 5
        },
        "Purple Flames Black ver" : {
            "files" : {
                "Purple Flames Black ver.png" : 50
                },
            "probability" : 5
        },
        "Purple Flames" : {
            "files" : {
                "Purple Flames.png" : 50
                },
            "probability" : 7
        },
        "RARW" : {
            "files" : {
                "RARW.png" : 50
                },
            "probability" : 7
        },
        "Red Flames" : {
            "files" : {
                "Red Flames.png" : 50
                },
            "probability" : 5
        },
        "Red Riot" : {
            "files" : {
                "Red Riot.png" : 50
                },
            "probability" : 5
        },
        "Rine" : {
            "files" : {
                "Rine.png" : 50
                },
            "probability" : 8
        },
        "Some Glasses" : {
            "files" : {
                "Some Glasses.png" : 50
                },
            "probability" : 8
        },
        "Summer" : {
            "files" : {
                "SUMMER.JPG" : 50
                },
            "probability" : 7
        },
        "Trip 2" : {
            "files" : {
                "Trip 2.png" : 50
                },
            "probability" : 2
        },
        "Trip" : {
            "files" : {
                "Trip.png" : 50
                },
            "probability" : 6
        },
        "Dots" : {
            "files" : {
                "Dots.png" : 50
                },
            "probability" : 7
        },
        "Numb" : {
            "files" : {
                "Numb.png" : 50
                },
            "probability" : 5
        },
        "Star" : {
            "files" : {
                "Star.png" : 50
                },
            "probability" : 3
        },
    },
    "Clothes" : {
        "Black Hoodie" : {
            "files" : {
                "Black Hoodie.png" : 60
                },
            "probability" : 9
        },
        "Bones" : {
            "files" : {
                "Bones.png" : 60
                },
            "probability" : 3
        },
        "The Vampyre" : {
            "files" : {
                "The Vampyre.png" : 60
                },
            "probability" : 3
        },
        "Camo" : {
            "files" : {
                "Camo.png" : 60
                },
            "probability" : 7,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Comfy" : {
            "files" : {
                "Comfy.png" : 60
                },
            "probability" : 7
        },
        "Cool Hoodie Dark" : {
            "files" : {
                "Cool Hoodie Dark.png" : 60
                },
            "probability" : 5,
            "exclude": ["Canti"]
        },
        "Cool Hoodie" : {
            "files" : {
                "Cool Hoodie.png" : 60
                },
            "probability" : 7,
            "exclude": ["Canti"]
        },
        "Dark Blue Hoodie" : {
            "files" : {
                "Dark Blue Hoodie.png" : 60
                },
            "probability" : 7
        },
        "Dark Gray Hoodie" : {
            "files" : {
                "Dark Gray Hoodie.png" : 60
                },
            "probability" : 9
        },
        "Full Black Hoodie" : {
            "files" : {
                "Full Black Hoodie.png" : 60
                },
            "probability" : 8,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Full Blue Hoodie" : {
            "files" : {
                "Full Blue Hoodie.png" : 60
                },
            "probability" : 8,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Full Dark Gray Hoodie" : {
            "files" : {
                "Full Dark Gray Hoodie.png" : 60
                },
            "probability" : 8,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Full Light Purple Hoodie" : {
            "files" : {
                "Full Light Purple Hoodie.png" : 60
                },
            "probability" : 8,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Full Purple Hoodie" : {
            "files" : {
                "Full Purple Hoodie.png" : 60
                },
            "probability" : 8,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Gray Blue Hoodie" : {
            "files" : {
                "Gray Blue Hoodie.png" : 60
                },
            "probability" : 9
        },
        "Gray Camo" : {
            "files" : {
                "Gray Camo.png" : 60
                },
            "probability" : 6,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Keep Gambling" : {
            "files" : {
                "Keep Gambling.png" : 60
                },
            "probability" : 5
        },
        "Light Blue Hoodie" : {
            "files" : {
                "Light Blue Hoodie.png" : 60
                },
            "probability" : 9
        },
        "MC Jacket" : {
            "files" : {
                "MC Jacket.png" : 60
                },
            "probability" : 5
        },
        "Mello Shiny" : {
            "files" : {
                "Mello Shiny.png" : 60
                },
            "probability" : 3
        },
        "Mello" : {
            "files" : {
                "Mello.png" : 60
                },
            "probability" : 5
        },
        "Metal" : {
            "files" : {
                "Metal.png" : 60
                },
            "probability" : 6
        },
        "No Cold" : {
            "files" : {
                "No Cold.png" : 60
                },
            "probability" : 7,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "None" : {
            "files" : {
                "None.png" : 60
                },
            "probability" : 3
        },
        "Not Selling" : {
            "files" : {
                "Not Selling.png" : 60
                },
            "probability" : 7
        },
        "Old Goth" : {
            "files" : {
                "Old Goth.png" : 60
                },
            "probability" : 4
        },
        "Straight Jacket" : {
            "files" : {
                "Straight Jacket.png" : 60
                },
            "probability" : 2
        },
        "White Comfy" : {
            "files" : {
                "White Comfy.png" : 60
                },
            "probability" : 5
        },
        "XMAS" : {
            "files" : {
                "XMAS.png" : 60
                },
            "probability" : 5,
            "exclude": ["Adventure", "Adventure black", "Black Headphones", "Bloody Chainsaw Frog", "Canti", "Chainsaw Frog", "Homer", "Max", "Mushroom", "Pepewifhat", "Samurai", "Scarf", "Yankee With No Brim", "Joker", "Crown", "Bane", "Top Hat", "Crow", "Armed Crow", "Parasyte"]
        },
        "Y2K" : {
            "files" : {
                "Y2K.png" : 60
                },
            "probability" : 5
        },
    },
    "Mouth" : {
        "Alien" : {
            "files" : {
                "Alien.png" : 70
                },
            "probability" : 5
        },
        "Angel Beats" : {
            "files" : {
                "Angel Beats.png" : 70
                },
            "probability" : 8
        },
        "Baby" : {
            "files" : {
                "Baby.png" : 70
                },
            "probability" : 8
        },
        "Cigar Psycho" : {
            "files" : {
                "Cigar Psycho.png" : 70
                },
            "probability" : 6
        },
        "Gold Snake Bites" : {
            "files" : {
                "Gold Snake Bites.png" : 70
                },
            "probability" : 7
        },
        "Sloppy Floppy" : {
            "files" : {
                "Hungry.png" : 70
                },
            "probability" : 8,
            "exclude": ["DOOM", "Bane"]
        },
        "Joker" : {
            "files" : {
                "Joker (No Hood).png" : 70
                },
            "probability" : 5,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Kiss" : {
            "files" : {
                "Kiss.png" : 70
                },
            "probability" : 7
        },
        "Loss of Control" : {
            "files" : {
                "Loss of Control.png" : 70
                },
            "probability" : 6
        },
        "Low Battery" : {
            "files" : {
                "Low Battery.png" : 70
                },
            "probability" : 4
        },
        "Stressed" : {
            "files" : {
                "Nicotine Boi.png" : 70
                },
            "probability" : 6
        },
        "No Bridge" : {
            "files" : {
                "No Bridge.png" : 70
                },
            "probability" : 7
        },
        "Silence" : {
            "files" : {
                "No Talk.png" : 70
                },
            "probability" : 4
        },
        "None" : {
            "files" : {
                "None.png" : 70
                },
            "probability" : 45
        },
        "Pink Lollipop" : {
            "files" : {
                "Pink Lollypop.png" : 70
                },
            "probability" : 6
        },
        "SAW" : {
            "files" : {
                "SAW.png" : 70
                },
            "probability" : 8
        },
        "Shiny Rocks" : {
            "files" : {
                "Shiny Rocks.png" : 70
                },
            "probability" : 5
        },
        "Smoke" : {
            "files" : {
                "Smoke.png" : 70
                },
            "probability" : 8
        },
        "Snake Bites" : {
            "files" : {
                "Snake Bites.png" : 70
                },
            "probability" : 6
        },
        "Vampire" : {
            "files" : {
                "Vampire.png" : 70
                },
            "probability" : 6
        },
        "Vomit" : {
            "files" : {
                "Vomit.png" : 70
                },
            "probability" : 6,
            "exclude" : ["Cross Joint", "Fire Shit", "Lollypop", "Poof Poof XL", "Poof Poof","Toothpick","Cuba","Gum","Rant","Rings"]
        },
        "Weirdo" : {
            "files" : {
                "Weirdo.png" : 70
                },
            "probability" : 7
        },
        "Coco" : {
            "files" : {
                "Coco.png" : 70
                },
            "probability" : 2
        },
        "Switchblade" : {
            "files" : {
                "Knife.png" : 70
                },
            "probability" : 3
        },
        "Nails" : {
            "files" : {
                "Nails.png" : 70
                },
            "probability" : 6
        },
        "Razor Blade" : {
            "files" : {
                "Razor Blade.png" : 70
                },
            "probability" : 6
        },
    },
    "Cigar" : {
        "Cross Joint" : {
            "files" : {
                "Cross Joint.png" : 80
                },
            "probability" : 3,
            "exclude": ["Bane"]
        },
        "Charmander" : {
            "files" : {
                "Charmander.png" : 80
                },
            "probability" : 2,
            "exclude": ["Bane"]
        },
        "Rip Bozo" : {
            "files" : {
                "Rip Bozo.png" : 80
                },
            "probability" : 2,
            "exclude": ["Bane"]
        },
        "Up Only" : {
            "files" : {
                "Up Only.png" : 80
                },
            "probability" : 2,
            "exclude": ["Bane"]
        },
        "Fire Shit" : {
            "files" : {
                "Fire Shit.png" : 80
                },
            "probability" : 5,
            "exclude": ["Bane"]
        },
        "Lollipop" : {
            "files" : {
                "Lollypop.png" : 80
                },
            "probability" : 6,
            "exclude": ["Bane"]
        },
        "None" : {
            "files" : {
                "None.png" : 80
                },
            "probability" : 35
        },
        "Poof Poof XL" : {
            "files" : {
                "Poof Poof XL.png" : 80
                },
            "probability" : 5,
            "exclude": ["Bane"]
        },
        "Poof Poof" : {
            "files" : {
                "Poof Poof.png" : 80
                },
            "probability" : 5,
            "exclude": ["Bane"]
        },
        "Toothpick" : {
            "files" : {
                "Toothpick.png" : 80
                },
            "probability" : 7,
            "exclude": ["Bane"]
        },
        "Cuban" : {
            "files" : {
                "Cuba.png" : 80
                },
            "probability" : 5,
            "exclude": ["Bane"]
        },
        "Gum" : {
            "files" : {
                "Gum.png" : 80
                },
            "probability" : 4,
            "exclude": ["Bane"]
        },
        "Rant" : {
            "files" : {
                "Rant.png" : 80
                },
            "probability" : 4,
            "exclude": ["Bane"]
        },
        "Rings" : {
            "files" : {
                "Rings.png" : 80
                },
            "probability" : 2,
            "exclude": ["Bane"]
        },
    },
    "Head" : {
        "Adventure" : {
            "files" : {
                "Adventure (No hood).png" : 90
            },
            "probability" : 5,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Adventure black" : {
            "files" : {
                "Adventure black (No hood).png" : 90
            },
            "probability" : 4,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Angel" : {
            "files" : {
                "Angel.png" : 90
            },
            "probability" : 5
        },
        "Crow" : {
            "files" : {
                "Crow.png" : 90
            },
            "probability" : 3
        },
        "Armed Crow" : {
            "files" : {
                "Armed Crow.png" : 90
            },
            "probability" : 1
        },
        "Baku" : {
            "files" : {
                "Baku.png" : 90
            },
            "probability" : 3
        },
        "Black Headphones" : {
            "files" : {
                "Black Headphones (no hood).png" : 90
            },
            "probability" : 9,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Black Horns" : {
            "files" : {
                "Black Horns.png" : 90
            },
            "probability" : 7
        },
        "Bloody Chainsaw Frog" : {
            "files" : {
                "Bloody Chainsaw Frog (No hood).png" : 90
            },
            "probability" : 5,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Canti" : {
            "files" : {
                "Canti (No Hood).png" : 90
            },
            "probability" : 7,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Chainsaw Frog" : {
            "files" : {
                "Chainsaw Frog (No hood).png" : 90
            },
            "probability" : 6,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Cobbee" : {
            "files" : {
                "Cobbee.png" : 90
            },
            "probability" : 8
        },
        "Crown" : {
            "files" : {
                "Crown.png" : 90
            },
            "probability" : 7,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Cyberfrog 4269" : {
            "files" : {
                "Cyberfrog 4269.png" : 90
            },
            "probability" : 5
        },
        "Demon Horns" : {
            "files" : {
                "Demonic Horns.png" : 90
            },
            "probability" : 3
        },
        "Detected" : {
            "files" : {
                "Detected.png" : 90
            },
            "probability" : 6
        },
        "For Sale" : {
            "files" : {
                "For Sale.png" : 90
            },
            "probability" : 4
        },
        "Gold Skull" : {
            "files" : {
                "Gold Skull.png" : 90
            },
            "probability" : 4
        },
        "Homer" : {
            "files" : {
                "Homer (No Hood).png" : 90
            },
            "probability" : 7,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Horns" : {
            "files" : {
                "Horns.png" : 90
            },
            "probability" : 8
        },
        "Instagram User" : {
            "files" : {
                "Instagram User.png" : 90
            },
            "probability" : 3
        },
        "Max" : {
            "files" : {
                "Max (no hood).png" : 90
            },
            "probability" : 8,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Mushroom" : {
            "files" : {
                "Mushroom (No Hood).png" : 90
            },
            "probability" : 4,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "None" : {
            "files" : {
                "None.png" : 90
            },
            "probability" : 45
        },
        "Party" : {
            "files" : {
                "Party.png" : 90
            },
            "probability" : 8
        },
        "Pepewifhat" : {
            "files" : {
                "Pepewifhat (No hood).png" : 90
            },
            "probability" : 6,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Parasyte" : {
            "files" : {
                "Parasyte.png" : 90
            },
            "probability" : 3,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Red Angel" : {
            "files" : {
                "Red Angel.png" : 90
            },
            "probability" : 4
        },
        "Samurai" : {
            "files" : {
                "Samurai (No Hood).png" : 90
            },
            "probability" : 6,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Scarf" : {
            "files" : {
                "Scarf (No Hoodie).png" : 90
            },
            "probability" : 7,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "NGMI" : {
            "files" : {
                "Shame Cone.png" : 90
            },
            "probability" : 6
        },
        "Skull" : {
            "files" : {
                "Skull.png" : 90
            },
            "probability" : 7
        },
        "Smol Beanie" : {
            "files" : {
                "Smol Beanie.png" : 90
            },
            "probability" : 9
        },
        "This is clearly money laundering" : {
            "files" : {
                "This is clearly money laundering.png" : 90
            },
            "probability" : 4
        },
        "Top Hat" : {
            "files" : {
                "Top Hat.png" : 90
            },
            "probability" : 9
        },
        "Trap" : {
            "files" : {
                "Trap.png" : 90
            },
            "probability" : 3
        },
        "Trick or Treat" : {
            "files" : {
                "Trick or Treat.png" : 90
            },
            "probability" : 7
        },
        "Yankee With No Brim" : {
            "files" : {
                "Yanke With No Brim (No Hood).png" : 90
            },
            "probability" : 6,
            "exclude" : ["Camo", "Full Black Hoodie", "Full Blue Hoodie", "Full Dark Gray Hoodie", "Full Light Purple Hoodie", "Full Purple Hoodie", "Gray Camo", "No Cold", "XMAS"]
        },
        "Bane" : {
            "files" : {
                "Bane.png" : 90
            },
            "probability" : 5,
        },
        "DOOM" : {
            "files" : {
                "Doom.png" : 90
            },
            "probability" : 3,
        },
    }
}

export default specs;