import { useState, useEffect } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

const RaffleCard = ({ raffle, address, tokenIds }: { raffle: any, address: any, tokenIds: any }) => {

    const [countdown, setCountdown] = useState(calculateCountdown());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    function calculateCountdown() {
        const endTime = new Date(raffle.endTime).getTime();
        const now = new Date().getTime();
        const distance = endTime - now;

        if (distance <= 0) {
            return 'Raffle has ended';
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }

    async function enterRaffle() {
        const response = await fetch(`${apiUrl}/raffles/enter/${raffle._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address,
                tokenIds: tokenIds
            })
        });

        if (response.ok) {
            alert('You have entered the raffle!');
        } else {
            let message = await response.json();
            alert(message.error);
        }
    }

    return (
        <div className={"raffleCard"}>
            <h3 className={"raffleHeading"}>{raffle.name}</h3>
            <img className={"raffleImage"} src={raffle.coverImageUrl} alt={raffle.name} />
            {countdown !== 'Raffle has ended' ? <p className={"raffleDuration"}>Ends in: {countdown}</p> : <p className={"raffleDuration"}>{countdown}</p>}
            {countdown !== 'Raffle has ended' ? <button className={"raffleButton"} onClick={enterRaffle}>Enter Raffle</button> : null}
        </div>
    );
};

export default RaffleCard;
